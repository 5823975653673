tbody {
    --bs-table-bg: rgba(247, 247, 248, 1);
}

thead {
    --bs-table-bg: rgba(196, 196, 196, 1);
}

body {
    font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !important;
    color:#404040;
}
p{
    color:#404040;
}
a{
    color: #1B74BA !important;
}
.bg-success{
    background: #19B356 !important;
}
.bg-secondary{
    background: #E8E8E8 !important;
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}
.bg-service-banner {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 1rem;
}

.ant-form-item .ant-form-item-explain-error {
    margin-bottom: 14px !important;
}
.active-card{
    border: 2px solid #0a0a0a !important;
}
.ant-picker-content tbody{
    background: #FFFFFF !important;
}
.my-membership-card{
    border-radius: 6px;
    background: linear-gradient(90deg, #101820 0%, #1B74BA 100%);
    box-shadow: 0px 1px 2px 0px rgba(10, 10, 10, 0.12);
}
.ant-card .ant-card-head {
    border: none !important;
}
.ant-tabs{
    font-size: 16px !important;
}

.loaders {
    width: 48px;
    height: 48px;
    border: 5px solid #0c1e91;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* adjust your icon size, add different breakpoints as needed */
@media screen and (max-width: 600px) {
    .video-wrap .play-btn {
        width: 70px;
        height: 70px;
    }
}